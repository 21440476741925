import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/main.scss";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={`navbar navbar-expand-lg ${isScrolled ? "scrolled" : ""}`}>
      <div className="container">
        <a className="navbar-brand text-white" href="home">Armed Armadillo Studios</a>
        
        <button 
          className="navbar-toggler" 
          type="button" 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item"><Link className="nav-link" to="home" smooth={true} duration={500}>Home</Link></li>
            <li className="nav-item"><Link className="nav-link" to="about" smooth={true} duration={500}>About</Link></li>
            <li className="nav-item"><Link className="nav-link" to="services" smooth={true} duration={500}>Services</Link></li>
            <li className="nav-item"><Link className="nav-link" to="contact" smooth={true} duration={500}>Contact</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
