import React from "react";
import { FaPaintBrush, FaCode, FaCloud, FaCube, FaEthereum, FaQuoteLeft } from "react-icons/fa";

const Section = ({ id, className, title }) => {
  // Define content for each section
  const sectionContent = {
    home: {
      heading: "Welcome to Armed Armadillo Studios",
      text: "A cutting-edge design and development studio specializing in creative solutions for the modern digital age.",
    },
    services: {
      heading: "Our Services",
      text: "Explore the wide range of services we offer to help your business thrive in the digital world.",
      services: [
        { icon: <FaPaintBrush />, title: "Graphic Design", description: "Branding, logos, marketing materials, UI/UX design, and more." },
        { icon: <FaCode />, title: "Web & App Design", description: "Custom websites and applications tailored to your brand." },
        { icon: <FaCloud />, title: "SaaS Development", description: "Building scalable Software-as-a-Service platforms." },
        { icon: <FaCube />, title: "Blockchain Solutions", description: "Smart contracts, DeFi apps, and secure blockchain integrations." },
        { icon: <FaEthereum />, title: "Web3 Integration", description: "Decentralized apps (dApps), NFTs, and blockchain-driven experiences." },
      ],
    },
    about: {
      heading: "About Us",
      text: "We are a multifaceted design and development studio passionate about crafting innovative experiences. From branding and graphic design to advanced web applications and blockchain integration, we bring ideas to life with creativity and precision.",
    },    
    testimonials: {
      heading: "What Our Clients Say",
      text: "Real feedback from satisfied partners who have transformed their ideas into reality with us.",
      testimonials: [
        { quote: "The branding they created elevated our company to a whole new level!", author: "Emily R.", company: "Creative Co." },
        { quote: "Their web design expertise turned our outdated site into a sleek, modern experience!", author: "James K.", company: "Techify Solutions" },
        { quote: "Seamless SaaS integration with top-notch support. Highly recommend!", author: "Sarah L.", company: "CloudGen" },
      ],
    },
    portfolio: {
      heading: "Our Work",
      text: "A showcase of some of our best projects.",
      projects: [
        { title: "Brand Identity for NeonX", image: "/assets/images/project1.webp" },
        { title: "E-commerce Web App", image: "/assets/images/project2.webp" },
        { title: "NFT Marketplace Design", image: "/assets/images/project3.webp" },
        { title: "SaaS Dashboard UI/UX", image: "/assets/images/project4.webp" },
      ],
    },
    contact: {
      heading: "Get in Touch",
      text: "Let's collaborate! Reach out to us for custom solutions tailored to your business.",
      email: "contact@armedarmadillostudios.com",
    },
  };

  return (
    <section id={id} className={`section ${className}`}>
      <div className="container text-center">
        <h1>{sectionContent[id].heading}</h1>
        <p>{sectionContent[id].text}</p>

        {/* Modern Grid for Services */}
        {id === "services" && (
          <div className="services-grid">
            {sectionContent.services.services.map((service, index) => (
              <div key={index} className="service-card">
                <div className="service-icon">{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        )}

        {/* Testimonials Section */}
        {id === "testimonials" && (
          <div className="testimonial-list">
            {sectionContent.testimonials.testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <FaQuoteLeft className="quote-icon" />
                <p className="quote-text">"{testimonial.quote}"</p>
                <h4>- {testimonial.author}, {testimonial.company}</h4>
              </div>
            ))}
          </div>
        )}

        {/* Portfolio Section */}
        {id === "portfolio" && (
          <div className="portfolio-grid">
            {sectionContent.portfolio.projects.map((project, index) => (
              <div key={index} className="portfolio-item">
                <img src={project.image} alt={project.title} />
                <h3>{project.title}</h3>
              </div>
            ))}
          </div>
        )}

        {/* Contact Section */}
        {id === "contact" && (
          <div className="contact-info">
            <p>Email us at: <a href={`mailto:${sectionContent.contact.email}`}>{sectionContent.contact.email}</a></p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Section;
