import React from "react";
import Header from "./components/Header";
import Section from "./components/Section";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <Header />
      <Section id="home" className="home" title="Welcome" />
      <Section id="about" className="about" title="About Us" />
      <Section id="portfolio" className="portfolio" title="Portfolio" />
      <Section id="services" className="services" title="Our Services" />
      <Section id="contact" className="contact" title="Contact Us" />
      <Footer />
    </>
  );
};

export default App;
